import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
​
const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'profil', loadChildren: './pages/profil/profil.module#ProfilPageModule' },
  { path: 'knowledgecard', loadChildren: './pages/knowledgecard/knowledgecard.module#KnowledgecardPageModule' },
  { path: 'jobcard', loadChildren: './pages/jobcard/jobcard.module#JobcardPageModule' },
  { path: 'jobcardview/:myId', loadChildren: './pages/jobcardview/jobcardview.module#JobcardviewPageModule' },
];
​
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
