import { Injectable } from '@angular/core';

import { NavController } from '@ionic/angular';

import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FluxService {
  data: any = undefined;
  dataLive: number = 0;

  f: any = {
    "default": {
      "error401": "login",
      "on_login": "home",
      "jobcard": "jobcard",
      "on_signup": "register",
      "home": "home",
      "disconnect": "login",
    }
  };

  a: string = "default";

  menu: any = {
    "default": [
      {
        title: 'Dashboard',
        url: '/home',
        icon: 'stats'
      },
      {
        title: 'Le Saviez-vous',
        url: '/knowledgecard',
        icon: 'help'
      },
      {
        title: 'Fiches Métiers',
        url: '/jobcard',
        icon: 'document'
      },
      {
        title: 'Profil',
        url: '/profil',
        icon: 'person'
      },
      {
        title: 'Autres',
        url: '',
        icon: ''
      },
    ]
  }


  constructor(public navCtrl: NavController) {
  }

  setData(e){
    this.dataLive = 2;
    this.data = e;
  }

  getData(){
    return this.data;
  }


  setA(e){
    if(this.f[e] == undefined){
      this.a = "default";
    } else {
      this.a = e;
    }
  }

  getMenu(){
    if(this.menu[this.a] != undefined){
      return this.menu[this.a];
    }
    return this.menu["default"]
  }

  clearData(){
    this.dataLive--;
    if(this.dataLive <= 0){
      this.data = undefined;
    }
  }

  navigateRoot(e){
    this.clearData();
    
    if(this.f[this.a][e] == undefined){
      this.navCtrl.navigateRoot(this.f["default"][e]);
    } else {
      this.navCtrl.navigateRoot(this.f[this.a][e]);
    }
  }

  navigateForward(e){
    this.clearData();

    if(this.f[this.a][e] == undefined){
      this.navCtrl.navigateForward(this.f["default"][e]);
    } else {
      this.navCtrl.navigateForward(this.f[this.a][e]);
    }
  }


  navigateForwardRaw(e){
    this.navCtrl.navigateForward(e);
  }
}
